<template>
  <div>
    <Table row-key="id" ref="body" :tooltip-theme="$store.state.app.theme" :height="tableHeight" :loading="loading"
      :columns="tableColumns" :data="tableData" stripe border>
    </Table>
    <Page :total="total" :current="currentPage" @on-change="handleCurrentChange" @on-page-size-change="handlePageSize"
      prev-text="上一页" next-text="下一页" ref="footer" show-sizer style="float: right" />
  </div>
</template>
<script>
import tablePageMixin from '_m/tablePage.mixin'
import tableHeightMixin from '_m/tableHeight.mixin'
import { handleGetEnumValue, timeFormat } from '@/utils'
import { orderExportStatusEnum } from '_e/index'
export default {
  mixins: [tablePageMixin, tableHeightMixin],
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  computed: {
    tableColumns () {
      const columns = []
      columns.push({
        title: '任务名称',
        align: 'center',
        key: 'name',
      })
      columns.push({
        title: '开始时间',
        align: 'center',
        key: 'taskConditions',
        render: (h, params) => {
          const { row } = params
          const text = JSON.parse(row.taskConditions).startTime || timeFormat(JSON.parse(row.taskConditions).sDate)
          return h('div', undefined, text)
        },
      })
      columns.push({
        title: '结束时间',
        align: 'center',
        key: 'taskConditions',
        render: (h, params) => {
          const { row } = params
          const text = JSON.parse(row.taskConditions).endTime || timeFormat(JSON.parse(row.taskConditions).eDate)
          return h('div', undefined, text)
        },
      })
      columns.push({
        title: '任务状态',
        align: 'center',
        key: 'status',
      })
      columns.push({
        title: '操作',
        align: 'center',
        render: (h, params) => {
          const { row } = params
          const array = []
          if (row.status === '导出成功') {
            array.push(
              h(
                'Button',
                {
                  props: {
                    size: 'small',
                    type: 'success',
                    icon: 'ios-cog-outline',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.handleDownload(row)
                    },
                  },
                },
                '下载'
              )
            )
          }
          return h('div', array)
        },
      })
      return columns
    },
  },
  created () {
    this.initData()
  },
  methods: {
    // modalChange (val) {
    //   if (val) {
    //     console.log(val)
    //     this.initData()
    //   }
    // },
    /**
     * 查询导出任务分页数据
     */
    initData () {
      this.loading = true
      this.$http.get(`/portal/v1/biz-export-task/m/findListByPage`, {
        params: {
          page: this.currentPage,
          pageCount: this.pageSize,
        }
      }).then((response) => {
        const { code, data } = response
        if (code === 1) {
          console.log(response)
          const { total, records } = data
          this.total = total
          this.tableData = response.data.records.map((item) => {
            item.status = handleGetEnumValue(orderExportStatusEnum, item.status)
            return item
          })
        }
      })
        .finally(() => {
          this.loading = false
        })
    },
    // 下载
    handleDownload (row) {
      let alink = document.createElement('a')
      fetch(row.downloadLink, { responseType: 'blob' }).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        alink.href = URL.createObjectURL(blob)
        console.log(alink.href)
        alink.download = row.name + '.xlsx'
        document.body.appendChild(alink)
        alink.click()
        this.$Message.success('下载成功')
      })
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .ivu-modal-body {
  padding: 45px 0 65px;
}
</style>
